<!--左侧边栏-->
<template>
  <div class="sider">
    <!--用户头像部分-->
    <div class="header">
      <div class="sys--title">标签管理系统</div>
      <div class="avatar">
        <img src="@/assets/image/login-centerBg.png" />
      </div>
      <div class="flexStyle">
        <div>{{ nickname }}</div>
        <a @click="logOut">退出</a>
      </div>
    </div>
    <!--导航栏部分-->
    <div class="nav">
      <a-menu
        mode="inline"
        :default-selected-keys="[menuValue]"
        @click="clickMenu"
      >
        <a-menu-item key="SystemTag">
          <a-icon type="copy" />
          <span>系统标签管理</span>
        </a-menu-item>
        <a-menu-item key="SelfDefineTag" v-if="roleIds.includes('SYS_ADMIN')">
          <a-icon type="edit" />
          <span>自定义标签管理</span>
        </a-menu-item>
        <a-menu-item key="TagGroup">
          <a-icon type="form" />
          <span>标签组管理</span>
        </a-menu-item>
        <a-menu-item key="DataStatistics" v-if="roleIds.includes('SYS_ADMIN')">
          <a-icon type="pie-chart" />
          <span>数据统计</span>
        </a-menu-item>
        <a-menu-item key="AdminManage" v-if="roleIds.includes('SYS_ADMIN')">
          <a-icon type="user" />
          <span>管理员管理</span>
        </a-menu-item>
        <a-menu-item key="ApiSecuraty" v-if="roleIds.includes('SYS_ADMIN')">
          <a-icon type="radar-chart" />
          <span>接口安全控制</span>
        </a-menu-item>
        <a-menu-item key="ProjectList" v-if="roleIds.includes('SYS_ADMIN')">
          <a-icon type="inbox" />
          <span>项目词典</span>
        </a-menu-item>
        <a-menu-item key="TranslateWords" v-if="roleIds.includes('SYS_ADMIN')">
          <a-icon type="block" />
          <span>公共词典</span>
        </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: "SiderBar",
  data() {
    return {
      roleIds: localStorage.getItem("roleIds"),
      nickname: localStorage.getItem("nickName"),
      menuValue: ""
    };
  },
  created() {
    this.menuValue = this.$route.name;
  },
  methods: {
    // 退出登录
    logOut() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
    },
    // 点击菜单
    clickMenu(item) {
      this.$router.push({
        name: item.key
      });
    }
  }
};
</script>
<style scoped>
.sider {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header {
  padding: 20px;
  border-bottom: 1px solid #e8e8e8;
  background: white;
  border-right: 1px solid #e8e8e8;
}
.nav {
  flex-grow: 1;
}
.ant-menu {
  height: 100%;
}
.sys--title {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.avatar img {
  width: 160px;
  margin: 10px 0;
}
.flexStyle {
  display: flex;
  justify-content: space-between;
}
</style>
