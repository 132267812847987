import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  // 数据
  state: { },
  mutations: {

  },
  actions: {

  },
  plugins: [createPersistedState()]
});
