/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import { Modal } from "ant-design-vue";
import store from "../store/store";

Vue.prototype.confirm = Modal.confirm;

//解决点击相同菜单报NavigationDuplicated错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  // 登录
  {
    path: "/",
    name: "Login",
    meta: { useLayout: false },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/Login"),
  },
  // 系统标签管理
  {
    path: "/system-tag",
    name: "SystemTag",
    meta: { useLayout: true },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/SystemTag/index.vue"),
  },
  // 二级标签
  {
    path: "/second-tag",
    name: "SecondTag",
    meta: { useLayout: true },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/SystemTag/SecondTag.vue"),
  },
  // 自定义标签管理
  {
    path: "/self-define-tag",
    name: "SelfDefineTag",
    meta: { useLayout: true },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/SelfDefinedTag/index.vue"),
  },
  // 自定义标签二级标签
  {
    path: "/self-second-tag",
    name: "SelfSecondTag",
    meta: {useLayout: true},
    component: () => import(/* webpackChunkName: "Login" */ "@/views/SelfDefinedTag/SecondTag.vue"),
  },
  // 标签组管理
  {
    path: "/tag-group",
    name: "TagGroup",
    meta: { useLayout: true },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/TagGroup/index.vue"),
  },
  // 数据统计
  {
    path: "/data-statistics",
    name: "DataStatistics",
    meta: { useLayout: true },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/DataStatistics/index.vue"),
  },
  //管理员管理页
  {
    path: "/admin-manage",
    name: "AdminManage",
    meta: { useLayout: true },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/AdminManage/index.vue"),
  },
 // 接口安全控制
  {
    path: "/api-securaty",
    name: "ApiSecuraty",
    meta: { useLayout: true },
    component: () => import(/* webpackChunkName: "Login" */ "@/views/apiSecuraty/index.vue"),
  },
  // 添加术语
  {
    path: "/translate-words",
    name: "TranslateWords",
    meta: { useLayout: true },
    component: () =>
      import(/* webpackChunkName: "Message" */ "@/views/translateWords/index.vue"),
  },
  // 项目列表
  {
    path: "/project-list",
    name: "ProjectList",
    meta: { useLayout: true },
    component: () =>
      import(/* webpackChunkName: "Message" */ "@/views/translateWords/projectList.vue"),

  }
];

const router = new VueRouter({
  routes
});


router.afterEach(() => {
  NProgress.done();
});
export default router;
