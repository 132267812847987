<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <!-- 登录页-->
      <router-view v-if="!$route.meta.useLayout" />
      <!--内容页-->
      <a-layout v-if="$route.meta.useLayout">
        <a-layout-sider><sider-bar /></a-layout-sider>
        <a-layout>
          <a-layout-content>
            <router-view :key="$route.fullPath" />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import SiderBar from "@/components/SiderBar.vue";

export default {
  components: { SiderBar },
  data() {
    return {
      locale: zhCN
    };
  },
  watch: {}
};
</script>
<style>
#app {
  min-height: 100vh;
}
#app .ant-layout-sider {
  background: #ccc !important;
}
.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}
.ant-layout-content {
  background: white;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit !important;
}
</style>
